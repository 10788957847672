<template>
  <div class="layerBox">
    <el-tree
      class="mt20"
      :data="listByTypeData"
      node-key="id"
      :props="{
        label: 'name',
        children: 'outList'
      }"
      :expand-on-click-node="false"
      :default-expand-all="true"
    >
      <template v-slot="{ data, index }">
        <span class="custom-tree-node">
          <div class="jcsb">
            <div v-if="data.outList" class="mr10">
              <img v-show="data.name == '三维仿真'" src="./img/zs.png" alt />
              <img v-show="data.name == '二维正射'" src="./img/2w.png" alt />
              <img v-show="data.name == '三维正射'" src="./img/sw.png" alt />
            </div>
            <span
              class="text_container"
              :class="text_container_class(data.outList)"
              :title="data.name"
            >
              <span v-if="!data.outList" class="dib f25"
style="color: #50abff; font-weight: bold"
                >·</span
              >
              {{ data.name }}
            </span>
          </div>
          <div class="pr">
            <div v-if="!data.outList" class="dib posi pa top20" :class="itemClass_posi">
              <span
                class="cp tc w25 dib"
                :class="{ c3b7bb7: getIndex(data).childIndex != 0 }"
                @click="swapWithData(getIndex(data, index), true)"
                >↑</span
              >
              <span
                class="cp w25 dib tc"
                :class="{
                  c3b7bb7:
                    getIndex(data).childIndex !=
                    listByTypeData[getIndex(data).parentIndex].outList.length - 1
                }"
                @click="swapWithData(getIndex(data), false)"
                >↓</span
              >
            </div>
            <div v-if="!data.outList" :class="itemClass">
              <div
                v-for="item in totalMaps"
                :key="item"
                class="cp"
                @click="() => showArea(data, item)"
              >
                <span class="text">{{ showText(item, totalMaps) }}</span>
                <img v-if="modelList[item][data.id]" src="./img/eye.png" alt class />
                <img v-else src="./img/eye2.png" alt class />
              </div>
            </div>
          </div>
        </span>
      </template>
    </el-tree>
  </div>
</template>
<script>
const modelMap = { 1: new Map(), 2: new Map(), 3: new Map(), 4: new Map() };
const wmtsMap = { 1: new Map(), 2: new Map(), 3: new Map(), 4: new Map() };
import { ThreeDimensionalApi } from '@/api';
import { mapMutations, mapState } from 'vuex';
export default {
  data() {
    return {
      listByTypeData: [],
      modelList: { 1: {}, 2: {}, 3: {}, 4: {}}
    };
  },
  created() {
    this.listByTypeFn();
  },
  beforeDestroy() {
    // modelList.forEach(element => {
    //     viewer.scene.primitives.remove(element)
    // });
  },
  computed: {
    ...mapState('subplatform', ['totalMaps', 'linkage']),
    itemClass() {
      if (this.totalMaps === 2 || this.totalMaps === 3) {
        return 'totalMaps_2';
      } else if (this.totalMaps === 1) {
        return 'mt20';
      } else if (this.totalMaps === 4) {
        return 'totalMaps_4';
      }
    },
    itemClass_posi() {
      if (this.totalMaps === 2 || this.totalMaps === 3) {
        return 'right154';
      } else if (this.totalMaps === 1) {
        return 'right50';
      } else if (this.totalMaps === 4) {
        return 'right125';
      }
    }
  },
  methods: {
    text_container_class(outList) {
      let class_ = ' ';
      if (!outList) {
        class_ = 'lh60 ml15';
      }
      if (this.totalMaps == 1) {
        if (outList) {
          class_ += ' w198';
        } else {
          class_ += ' w190';
        }
      } else if (this.totalMaps == 2) {
        if (outList) {
          class_ += ' w198';
        } else {
          class_ += ' w126';
        }
      } else if (this.totalMaps == 3) {
        class_ += ' w106';
      } else if (this.totalMaps == 4) {
        class_ += ' w118';
      }
      return class_;
    },
    showText(num, totalMaps) {
      if (totalMaps == 1) {
        return '';
      } else if (totalMaps == 2) {
        return num === 1 ? '左' : '右';
      } else if (totalMaps == 3) {
        const text = {
          1: '左',
          2: '中',
          3: '右'
        };
        return text[num];
      } else if (totalMaps == 4) {
        const text = {
          1: '左上',
          2: '右上',
          3: '左下',
          4: '右下'
        };
        return text[num];
      }
    },
    swapWithData(data, flag) {
      const { parentIndex, childIndex } = data;
      const listByTypeDataCopy = JSON.parse(JSON.stringify(this.listByTypeData));

      if (flag) {
        if (childIndex > 0) {
          const temp = listByTypeDataCopy[parentIndex];
          [temp.outList[childIndex], temp.outList[childIndex - 1]] = [
            temp.outList[childIndex - 1],
            temp.outList[childIndex]
          ];
          this.$set(this.listByTypeData, parentIndex, temp);
        }
      } else {
        const temp = listByTypeDataCopy[parentIndex];
        if (childIndex < temp.outList.length - 1) {
          [temp.outList[childIndex], temp.outList[childIndex + 1]] = [
            temp.outList[childIndex + 1],
            temp.outList[childIndex]
          ];
          this.$set(this.listByTypeData, parentIndex, temp);
        }
      }
    },
    getIndex(node) {
      const parentNode = this.findParentNode(this.listByTypeData, node);
      return parentNode;
    },
    findParentNode(treeData, targetNode) {
      for (let i = 0; i < treeData.length; i++) {
        const node = treeData[i];
        if (node.outList && node.outList.includes(targetNode)) {
          return {
            parentIndex: i,
            childIndex: node.outList.indexOf(targetNode)
          };
        }
      }
    },
    showArea(data, item) {
      this.flyTo3D(data, true, item);
    },
    // 三维模型跳转
    async flyTo3D(data, isShow, item) {
      const areaViewers = window.viewers[item];
      // 显示
      if (isShow) {
        // 根据id判断modelMap中是否已经创建实例，是就改变属性显示并跳转，否就创建模型实例
        if (modelMap[item].has(data.id)) {
          // 隐藏模型实例
          const entities = modelMap[item].get(data.id);
          if (typeof entities === 'number') {
            // 超图的图层删除
            areaViewers.scene.layers.remove(entities);
          } else {
            areaViewers.scene.primitives.remove(entities);
          }

          modelMap[item].delete(data.id);
          this.$set(this.modelList[item], data.id, false);
        } else if (wmtsMap[item].has(data.id)) {
          const layer = wmtsMap[item].get(data.id);
          areaViewers.imageryLayers.remove(wmtsMap[item].get(data.id));
          this.$set(this.modelList[item], data.id, false);
          wmtsMap[item].delete(data.id);
        } else {
          if (data.type == 2) {
            // 2维wmts加载
            // get wmts链接获取当前wmts的配置
            const res = await fetch(data.url);
            const xmlString = await res.text();
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(xmlString, 'application/xml');
            const nodes = xmlDoc.getElementsByTagName('TileMatrix');
            const content = {
              /*  url: xmlDoc.querySelector("Contents Layer ResourceURL ")?.attributes
                  .getNamedItem('template')?.textContent || '', */
              url: data.url,
              layer:
                xmlDoc.querySelector('Contents Layer')?.getElementsByTagName('ows:Title')[0]
                  ?.textContent || '',
              style:
                xmlDoc
                  .querySelector('Contents Layer Style')
                  ?.getElementsByTagName('ows:Identifier')[0]?.textContent || '',
              format: xmlDoc.querySelector('Contents Layer Format')?.textContent || '',
              tileMatrixSetID:
                xmlDoc
                  .querySelector('Contents > TileMatrixSet')
                  ?.getElementsByTagName('ows:Identifier')[0]?.textContent || '',
              // rectangle: Cesium.Rectangle.fromDegrees(101.3, 37.0, 101.55, 37.10),
              tilingScheme: new Cesium.GeographicTilingScheme()
            };
            var imageryProvider = new Cesium.WebMapTileServiceImageryProvider(content);
            // viewer.imageryLayers.addImageryProvider(imageryProvider);
            await imageryProvider.readyPromise;

            const wmtsLayer = new Cesium.ImageryLayer(imageryProvider, {
              show: true
            });
            areaViewers.imageryLayers.add(wmtsLayer);
            this.$set(this.modelList[item], data.id, true);
            wmtsMap[item].set(data.id, wmtsLayer);
            // 相机转到该图层数据中标记的坐标
            const LowerCorner =
              xmlDoc
                .querySelector('Contents Layer')
                ?.getElementsByTagName('ows:WGS84BoundingBox')[0]
                ?.getElementsByTagName('ows:LowerCorner')[0].textContent || '';
            const UpperCorner =
              xmlDoc
                .querySelector('Contents Layer')
                ?.getElementsByTagName('ows:WGS84BoundingBox')[0]
                ?.getElementsByTagName('ows:UpperCorner')[0].textContent || '';

            const west = Number(LowerCorner.split(' ')[0]);
            const south = Number(LowerCorner.split(' ')[1]);
            const east = Number(UpperCorner.split(' ')[0]);
            const north = Number(UpperCorner.split(' ')[1]);

            const rectangle = Cesium.Rectangle.fromDegrees(west, south, east, north);
            areaViewers.camera.flyTo({
              destination: rectangle
            });
          } else {
            if (this.modelList[item] && this.modelList[item][data.id]) {
              this.modelList[item][data.id].show = !this.modelList[item][data.id].show;
              if (this.modelList[item][data.id].show) {
                areaViewers.flyTo(data.layer);
              }
            }
            // 判断是超图资源
            else if (data.url.includes('iserver')) {
              const layer = await areaViewers.scene.addS3MTilesLayerByScp(data.url, {
                name: item
                // cullEnabled: false
              });
              areaViewers.flyTo(layer);
              this.$set(this.modelList[item], data.id, true);
              modelMap[item].set(data.id, layer.name);
            } else {
              const resource = new Cesium.Resource({
                url: data.url,
                retryCallback: (resource, error) => {
                  if (!error.statusCode || error.statusCode === 404) {
                    this.$message.error('服务数据加载失败');
                  }
                  return false;
                },
                retryAttempts: 1
              });

              const entitie = areaViewers.scene.primitives.add(
                new Cesium.Cesium3DTileset({
                  id: data.id,
                  url: resource, // 数据路径
                  maximumScreenSpaceError: 2, // 最大的屏幕空间误差
                  maximumNumberOfLoadedTiles: 1000, // 最大加载瓦片个数
                  show: true
                })
              );

              areaViewers.flyTo(entitie);
              entitie.viewFrom = new Cesium.Cartesian3(0, 0, 1000);
              this.$set(this.modelList[item], data.id, true);
              modelMap[item].set(data.id, entitie);
            }
          }
        }
      }
    },
    closeIDsPopup() {
      this.$emit('closeDemand');
    },
    async listByTypeFn() {
      const res = await ThreeDimensionalApi.getGroupMap();
      if (res.code == 200) {
        this.listByTypeData = res.data;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.layerBox {
  height: 77vh;
  overflow-y: auto;

  .text_container {
    display: block;
    /* 设置容器宽度 */
    white-space: nowrap;
    /* 防止文本换行 */
    overflow: hidden;
    /* 隐藏超出容器的部分 */
    text-overflow: ellipsis;
    /* 超出部分显示省略号 */
  }

  .totalMaps_2 {
    display: flex;
    margin-top: 20px;

    .text {
      font-size: 12px;
    }

    img {
      margin-right: 10px;
      vertical-align: middle;
    }
  }

  .totalMaps_4 {
    width: 116px;
    display: flex;
    flex-wrap: wrap;
    margin-top: 7px;

    .text {
      font-size: 12px;
    }

    img {
      margin-right: 4px;
      vertical-align: middle;
    }

    // flex-basis: 50%;
  }
}

::v-deep {
  .el-tree {
    background: #0a0b0d;
  }

  .el-tree-node__content {
    display: flex;
    align-items: center;
    min-height: 50px;
    background: #0a0b0d;
    color: transparent;
  }

  .el-tree-node__content:hover {
    background: #35383c !important;
  }

  .el-tree-node:nth-child(odd) .el-tree-node__content {
    background: #14151a;
  }

  .el-tree-node:nth-child(even) .el-tree-node__content {
    background: #0a0b0d;
  }

  .el-tree-node:focus > .el-tree-node__content {
    background: transparent;
  }

  .posi {
    display: none;
  }

  .custom-tree-node {
    padding-right: 10px;
    display: flex;
    width: 100%;
    color: #b3bbc5;
    justify-content: space-between;
  }

  .custom-tree-node:hover {
    .posi {
      display: block;
    }
  }

  .c3b7bb7 {
    color: #3b7bb7;
  }
}
</style>
