<template>
  <div id="closeIDsPopupdiv ovh" class="closeIDsPopupdiv">
    <!-- 左侧列表 -->
    <div id="layerBox" class="layerBox">
      <el-input
        v-model="search"
        placeholder="输入标注名称搜索"
        size="mini "
        clearable
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search cp"
          @click="handleIconClick"
        />
      </el-input>
      <div class="layerBoxSelect mt8">
        <div class="layerBoxSelectItem mt13">
          <span
            :class="{ layerBoxSelectItem_active: SelectItem == 1 }"
            class="icon-shaixuan iconfont cp f23"
            @click="SelectItemFn(1)"
          />
          <el-tooltip placement="bottom">
            <div slot="content">
              <div class="info">
                <div class="cp mt5 text" @click="addMarker('point')">
                  添加点标注
                </div>
                <div class="cp mt10 text" @click="addMarker('line')">
                  添加线标注
                </div>
                <div class="cp mt10 text" @click="addMarker('polygon')">
                  添加面标注
                </div>
              </div>
            </div>
            <span
              :class="{ layerBoxSelectItem_active: SelectItem == 2 }"
              class="icon-tianjia f23 iconfont cp"
              @click="SelectItemFn(2)"
            />
          </el-tooltip>
          <span
            :class="{ layerBoxSelectItem_active: SelectItem == 3 }"
            class="icon-shanchu f23 iconfont cp"
            @click="SelectItemFn(3)"
          />
          <span class="cp">
            <img
              src="../img/3.png"
              alt=""
              title="报告标注"
              @click="showreportDialog"
            />
          </span>
          <span class="cp"
            ><img src="../img/1.png" title=" 全选" alt="" @click="all"
          /></span>
          <span class="cp"
            ><img src="../img/2.png" title="反选" @click="InvertSelection"
          /></span>
        </div>
        <div v-if="SelectItem == 1" class="mt19">
          <div class="cf f14">
            <div class="pr dib">
              <span class="ml10 dib"> 标签：</span
              ><span
                class="cp"
                style="color: #448ed4"
                @click="layerBoxSelectBoxflag = !layerBoxSelectBoxflag"
              >
                <div v-if="labels && labels.length > 0" class="dib w290 hidden">
                  <span v-for="(item, i) in labels" :key="i"
                    >{{ item.labelName
                    }}{{ labels.length - 1 == i ? "" : "、" }}</span
                  >
                </div>
                <div v-else class="dib">请选择标签</div>
              </span>
              <div v-if="layerBoxSelectBoxflag" class="layerBoxSelectBox">
                <div class="layerBoxSelectBox_left">
                  <div
                    v-for="(item, index) in tagTypeList"
                    :key="index"
                    class="mb7 label"
                    :class="{ active: index === selectIndex }"
                    @click="selectIndex = index"
                  >
                    {{ item.name }}
                  </div>
                </div>
                <div class="layerBoxSelectBox_right">
                  <div
                    v-if="
                      tagTypeList[selectIndex].labelOuts &&
                      tagTypeList[selectIndex].labelOuts.length > 0
                    "
                    class="label"
                  >
                    <el-checkbox v-model="isAll" @change="isAllFn" />
                    <span class="tag dib ml10"> 全部</span>
                  </div>
                  <div
                    v-for="(item, k) in tagTypeList[selectIndex] &&
                    tagTypeList[selectIndex].labelOuts"
                    v-show="item.labelName"
                    :key="k"
                    class="label"
                  >
                    <el-checkbox
                      v-model="item.status"
                      @change="checkboxChange"
                    />
                    <span class="tag dib ml10 name" :title="item.labelName">
                      {{ item.labelName }}</span
                    >
                  </div>
                </div>
                <div class="jcsb pa bottom14 w165 ml20">
                  <div class="btn2" @click="layerBoxSelectBoxFn">确定</div>
                  <div class="btn2" @click="layerBoxSelectBoxCancellation">
                    取消
                  </div>
                </div>
              </div>
            </div>
            <div class="searchSelect">
              <span>等级：</span>
              <el-select
                v-model="markLevel"
                style="width: 146px"
                placeholder="请选择等级"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </div>
            <div class="cf jcsb mb10 mt10 ml44 w250">
              <div class="btn2 w103" @click="getmarkerLists(1)">确定</div>
              <div class="btn2 w103" @click="CancellationSeach">取消</div>
            </div>
          </div>
        </div>
      </div>
      <div class="itemBox pr10">
        <div
          v-for="item in records"
          :key="item.id"
          class="item p6 pr"
          :class="{ itemActive: item.status }"
          @dblclick="dblclickFn(item)"
        >
          <div class="jcfs wih100 cp">
            <img
              class="w80 h72 dib"
              crossorigin="anonymous"
              :src="item.imageUrl || img"
              alt="暂无图片"
            />
            <div class="cf f12 ml8">
              <el-row class="h20 lh20 w231 hidden"
                ><span
                  style="color: #00c7ff"
                  class="iconfont f12 icon-chengguoku"
                />
                <img
                  :src="require('../img/' + item.markType + '.png')"
                  alt=""
                />
                <span class="title ml4 f14">{{ item.markName }}</span>
                <span>
                  <el-checkbox
                    v-model="item.status"
                    style="position: absolute"
                    class="top0 right0 cp"
                    @change="checkboxCheckbox(item)"
                  />
                </span>
              </el-row>
              <el-row class="h20 lh20 f12 time"> {{ item.createdDate }}</el-row>
              <el-row class="h20 lh20 time">
                {{ item.place || "暂无地址" }}
              </el-row>
            </div>
          </div>
          <div class="f14 cf mt10">
            <el-row v-if="item.markLabes.length > 0" class="lh24"
              ><span
                style="color: #0058ff"
                class="iconfont f14 mr10 icon-chengguoku"
              /><span v-for="i in item.markLabes" class="tags">{{
                i.name
              }}</span></el-row
            >
          </div>
          <div class="tr item_bottom">
            <div
              class="cf mr10 w60 h25 lh25 cp tc f9 bra2"
              style="background: #409eff"
              @click="editDimension(item)"
            >
              <span class="iconfont icon-bianji f12 cp" /> 编辑
            </div>
            <div
              class="cf w60 h25 lh25 cp tc f9 bra2"
              style="background: #409eff"
              @click="delItem(item.id)"
            >
              <span class="icon-shanchu f12 iconfont cp" />删除
            </div>
          </div>
        </div>
      </div>
      <pagination
        :report-data="reportData"
        :pagination="paginationData"
        class="pa bottom-44 left0"
        @handleCurrentChange="handleCurrentChange"
      />
    </div>
    <!-- 添加标注 -->
    <div
      class="dialog_box"
      v-if="form.markType"
      v-loading="loading"
      element-loading-text="提交请求中,请稍等!"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      @on-close="closeFn"
    >
      <div class="jcsb titleBox" v-interact>
        <div class="title">{{ title }}</div>
        <div @click="closeFn" class="close">X</div>
      </div>
      <div class="jcfs markTypeDialog">
        <img
          class="w242 ml12 mt10 h128 dib"
          crossorigin="anonymous"
          :src="dialogImg || img"
          alt="暂无图片"
        />
        <div class="ml10 cf h30 lh30">
          <el-row class="cp">
            <div title="更新位置" class="target dib cf" @click="updateFn">
              <img
                class="w18 ml3 mt3 h18"
                src="../img/mb.svg"
                mode="scaleToFill"
              />
            </div>
          </el-row>
          <el-row class="cp">
            <div
              title="截取封面"
              class="target dib cf"
              @click="handle_screenshot"
            >
              <img
                class="w18 ml3 mt3 h18"
                src="../img/jt.svg"
                mode="scaleToFill"
              />
            </div>
          </el-row>
          <el-row class="cp">
            <div title="飞入标注" class="target dib cf" @click="handle_flyTo">
              <img
                class="w18 ml3 mt3 h18"
                src="../img/tz.svg"
                mode="scaleToFill"
              />
            </div>
          </el-row>
        </div>
      </div>
      <div class="jcsb list mt32">
        <div
          v-for="(item, index) in table[form.markType]"
          :key="index"
          :class="index == itemIndex ? 'active' : ''"
          class="item"
          @click="changeTble(index)"
        >
          {{ item }}
        </div>
      </div>
      <div class="mt20 h425 cf" style="overflow-y: auto">
        <el-form
          ref="formRef"
          size="mini"
          :model="form"
          :rules="rules"
          label-width="80px"
        >
          <div v-show="itemIndex == 0">
            <el-form-item label="标注名称" prop="title">
              <el-input v-model="form.markName" placeholder="请输入标注名称" />
            </el-form-item>
            <el-form-item label="等级" width="315">
              <el-select v-model="form.markLevel" placeholder="请选择等级">
                <el-option label="1" :value="1" />
                <el-option label="2" :value="2" />
                <el-option label="3" :value="3" />
                <el-option label="4" :value="4" />
              </el-select>
            </el-form-item>
            <el-form-item label="经度">
              {{ form.lng }}
            </el-form-item>
            <el-form-item label="纬度">
              {{ form.lat }}
            </el-form-item>
            <el-form-item label="标签">
              <el-select
                v-model="form.markLabes"
                collapse-tags
                multiple
                style="margin-left: 20px"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in tagList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="地址">
              <el-input v-model="form.place" mark-type="textarea" />
            </el-form-item>
          </div>
          <!-- 点标注 -->
          <div v-show="form.markType == 'POINT'">
            <el-form-item v-show="itemIndex == 1" label="图标">
              <el-select v-model="form.styleType" placeholder="请选择图标">
                <el-option
                  v-for="item in iconArr"
                  :key="item.id"
                  :label="item.icoName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </div>
          <!-- 线标注 -->
          <div v-show="form.markType == 'LINESTRING'">
            <el-form-item v-show="itemIndex == 1" label="线宽">
              <el-input v-model="form.styleLineSize" placeholder="请输入线宽" />
            </el-form-item>
            <el-form-item v-show="itemIndex == 1" label="颜色">
              <el-color-picker v-model="form.styleLineOutColor" class="w153" />
            </el-form-item>
            <el-form-item v-show="itemIndex == 1" label="高程">
              <el-select v-model="form.styleType" placeholder="请选择等级">
                <el-option label="海拔高程" value="1" />
                <el-option label="贴地表对象" value="2" />
              </el-select>
            </el-form-item>
          </div>
          <!-- 面标注 -->
          <div v-show="form.markType == 'POLYGON'">
            <el-form-item v-show="itemIndex == 1" label="颜色">
              <el-color-picker v-model="form.styleLineAreaColor" class="w153" />
            </el-form-item>
            <el-form-item
              v-show="itemIndex == 0"
              label="面积(m²)"
              prop="markSize"
            >
              <el-input v-model="form.markSize" placeholder="请输入区域面积" />
            </el-form-item>
            <el-form-item v-show="itemIndex == 1" label="高程">
              <el-select v-model="form.styleType" placeholder="请选择高程">
                <el-option label="海拔高程" :value="1" />
                <el-option label="贴地表对象" :value="2" />
              </el-select>
            </el-form-item>
          </div>
          <div v-show="itemIndex == 2">
            <el-form-item label="流地址">
              <el-input
                @input="handleInputQj('streamUrl')"
                v-model="form.streamUrl"
                placeholder="请输入实时监控地址"
              ></el-input>
            </el-form-item>
            <el-form-item label="流地址类型">
              <el-select
                v-model="form.streamUrlType"
                style="width: 185px"
                placeholder="请选择流地址类型"
                clearable
              >
                <el-option
                  v-for="item in streamUrlTypeOptions"
                  :key="item.name"
                  :label="item.label"
                  :value="item.name"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="图片">
              <uploadFile ref="uploadFiledom"></uploadFile>
            </el-form-item>
            <el-form-item label="视频">
              <uploadVideo ref="uploadVideo"></uploadVideo>
            </el-form-item>
            <el-form-item label="水位测点">
              <el-select
                v-model="form.waterType"
                style="width: 185px"
                placeholder="请选择水位测点"
                clearable
              >
                <el-option
                  v-for="item in swOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="全景图">
              <el-input
                @input="handleInputQj('panoramaUrl')"
                v-model="form.panoramaUrl"
                placeholder="请输入全景图链接"
              ></el-input>
            </el-form-item>
            <el-form-item v-show="itemIndex == 2" label="备注">
              <el-input v-model="form.remark" mark-type="textarea"></el-input>
            </el-form-item>
          </div>
          <el-form-item>
            <div class="wih100">
              <div class="cf ml60 btn" @click="onSubmit">确定</div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 报告标注排序 -->
    <div v-if="reportDialog" class="reportBox">
      <div class="header">
        <span class="title">报告标注排序</span>
        <span class="close cp" @click="reportDialog = false">X</span>
      </div>
      <div class="pl30 pr30 screen">
        <span class="mt6 mr10">排序方式:</span>
        <el-select
          v-model="reportStatus"
          style="width: 185px"
          placeholder="请选择等级"
          @change="changeReportStatus"
        >
          <el-option
            v-for="item in reportOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
      <div class="content">
        <div class="th"><span class="mr5">标注名称</span><span>等级</span></div>
      </div>
      <div class="report_list">
        <div v-for="item in reportData" :key="item.id" class="item f14 jcsb">
          <div
            :title="item.markName"
            style="flex: 1"
            class="report_list_title tc dib"
          >
            {{ item.markName }}
          </div>
          <span style="flex: 1" class="tc dib">{{ item.markLevel }}</span>
        </div>
      </div>
      <div class="cf jcsb mb10 mt10 ml44 w250">
        <div class="btn2 w103" @click="showInspectionReport">确定</div>
        <div class="btn2 w103" @click="reportDialog = false">取消</div>
      </div>
    </div>
    <div v-if="InspectionReport" class="InspectionReport">
      <div class="header">
        <span class="title">报告标注</span>
        <span class="close cp" @click="InspectionReport = false">X</span>
      </div>
      <div class="InspectionReport-label tr pt10 cp">
        <span class="f14" style="color: #000">
          报告详情
          <img class="vam" src="../img/4.png" alt="" @click="saveReport"
        /></span>
      </div>
      <div id="chartType" />
      <!-- <div class="p24 h660" style="overflow-y: auto" v-if="wordDocumentUrl">
        <vue-office-docx :options="configOptions" :src="wordDocumentUrl" />
      </div> -->
    </div>
    <div id="Map" />
  </div>
</template>
<script>
import VueOfficeDocx from "@vue-office/docx";
import "@vue-office/docx/lib/index.css";
import Dialog from "@/components/dialog/index.vue";
import screenshot from "@/utils/CesiumFunction/Screenshot";
import dataURItoBlob from "@/utils/dataURItoBlob";
import drawGraphic from "@/utils/CesiumFunction/DrawGraphic";
import JsonExcel from "vue-json-excel";
import { AirLine } from "@/api";
import pagination from "../com/pagination/index.vue";
import * as echarts from "echarts";
import message from "./message/index.js";
import data from "./data/index.js";
// let bubblesList = [];
import {
  createPoint,
  createLine,
  createPolygon,
} from "@/utils/CesiumFunction/CreateLabel";
let dataEntities = null;
let viewerIndex = 1; // dataEntities Viwer 索引
let markerArr = [];
let viewer;
let viewers;
import { ThreeDimensionalApi, Map, EarlyWarningAPI } from "@/api";
import uploadFile from "../com/uploadFile";
import uploadVideo from "../com/uploadVideo";
import EntitiesModel from "../com/entitiesModel";
import Bubble from "./message/bubbleModel/index.js";
let clickHandler = null;
export default {
  components: {
    Dialog,
    JsonExcel,
    pagination,
    VueOfficeDocx,
    uploadFile,
    uploadVideo,
    EntitiesModel,
  },
  data() {
    return {
      ...data,
      loading: false,
      entitiesModelData: {},
      entitiesModelFlag: true,
      markLevel: 0,
      streamUrlTypeOptions: [
        { label: "RTSP", value: 1, name: "rtsp" },
        { label: "HLS", value: 2, name: "HLS" },
        { label: "FLV", value: 3, name: "flv" },
        { label: "WEBRTC", value: 4, name: "webrtc" },
        { label: "QingLiu", value: 5, name: "QingLiu" },
      ],
      swOptions: [
        {
          label: "水位测点1",
          value: "水位测点1",
        },
        {
          label: "水位测点2",
          value: "水位测点2",
        },
        {
          label: "水位测点3",
          value: "水位测点3",
        },
        {
          label: "水位测点4",
          value: "水位测点4",
        },
        {
          label: "水位测点5",
          value: "水位测点5",
        },
      ],
      form: {
        panoramaUrl: "全景图地址",
        waterType: null,
        streamUrl: "",
        markSize: null, // 面积
        code: null,
        place: null,
        lng: null,
        lat: null,
        markLevel: 1,
        markName: "默认标注",
        remark: "",
        markType: null, // 标注类型 1点标注 2 线标注 3面标注
        imageUrl: null,
        styleLineSize: 5, // 线宽
        styleType: 1, // 高程
        styleLineAreaColor: "#ea4141", // 面积颜色
        styleLineOutColor: "#ea4141", // 颜色
        markLine: null,
        streamUrlType: null,
      },
      wordDocumentUrl: null,
      // word 文件
      wordFile: null,
      // 台账报告弹窗
      reportDialog: false,
      reportData: [],
      // 巡检报告
      InspectionReport: false,
      labels: null,
      isAll: false,
      wordUrl: null,
      reportStatus: null,
      // 每页选中的数据
      activeItems: {},
      selectIndex: 0,
      tagTypeList: [],
      active: 0,
      layerBoxSelectBoxflag: false,
      SelectItem: 0,
      showTooltip: true,
      VUE_APP_IMGIP: "",
      records: [],
      jsonExcelData: [],
      checkList: null,
      paginationData: {},
      iconArr: [],
      search: "",
      title: "",
      itemIndex: 0, // table 0 代表基本信息 1 代表样式设计  2 代表备注说明
      tagList: [],
      handle_screenshotFlag: true,
      viewerIndex: 1, // 当前控制 viewer 索引
      bubbles: null,
      bubblesId: null, //标注id
    };
  },
  watch: {
    selectIndex() {
      this.checkboxChange();
    },
    "$store.state.uavApplications.dimensionReset"(nweval) {
      if (nweval) {
        this.closeFn();
      }
    },
    "$store.state.uavApplications.mapRadio"(index) {
      if (viewers) {
        this.viewerIndex = index;
        viewer = viewers[index];
      } else {
        viewer = window.viewer;
        this.viewerIndex = 1;
      }
    },
    "form.markName"(markName) {
      if (dataEntities) {
        dataEntities.label.text = markName;
      }
    },
    "form.styleType"(id) {
      // 点标注切换图标
      if (dataEntities && this.form.markType == "POINT") {
        const data = this.iconArr.filter((res) => {
          return res.id == id;
        });
        if (data.length > 0) {
          dataEntities.billboard.image = data[0].icoUrl;
        }
      }
    },
    "form.styleLineSize"(num) {
      if (typeof parseInt(num) === "number") {
        if (dataEntities && this.form.markType == "LINESTRING") {
          dataEntities.polyline.width = parseInt(num);
        }
      } else {
        this.$message.warning("请输入数字！");
      }
    },
    "form.styleLineOutColor"(color) {
      if (dataEntities) {
        const { form } = this;
        if (form.markType == "LINESTRING") {
          dataEntities.polyline.material =
            Cesium.Color.fromCssColorString(color);
        }
      }
    },
    "form.styleLineAreaColor"(color) {
      if (dataEntities) {
        const { form } = this;
        if (form.markType == "POLYGON") {
          dataEntities.polygon.material =
            Cesium.Color.fromCssColorString(color);
        }
      }
    },
  },
  created() {
    this.iconArr = [
      {
        id: 1,
        icoName: "区政府",
        icoUrl: require("@/assets/home/subplatForm/icon/1.png"),
      },
      {
        id: 2,
        icoName: "日常服务",
        icoUrl: require("@/assets/home/subplatForm/icon/2.png"),
      },
      {
        id: 3,
        icoName: "三小场所",
        icoUrl: require("@/assets/home/subplatForm/icon/3.png"),
      },
      {
        id: 4,
        icoName: "商场超市",
        icoUrl: require("@/assets/home/subplatForm/icon/4.png"),
      },
      {
        id: 5,
        icoName: "山峰",
        icoUrl: require("@/assets/home/subplatForm/icon/5.png"),
      },
    ];
    // this.getIcon()
    const { VUE_APP_IMGIP } = process.env;
    this.VUE_APP_IMGIP = VUE_APP_IMGIP;
    this.tagListFn();
    this.getmarkerLists();
  },
  beforeDestroy() {
    if (dataEntities) {
      viewers[viewerIndex].entities.remove(dataEntities);
    }
    markerArr.forEach((element) => {
      viewers[viewerIndex].entities.remove(element.entities);
    });
    if (this.bubbles) {
      this.bubbles.windowClose();
      this.bubbles = null;
    }
    markerArr = [];
    dataEntities = null;
    this.closeFn();
  },
  mounted() {
    // 多屏地图实例
    viewers = window.viewers;
    if (viewers) {
      // 当前选中地图实例
      const index = this.$store.state.uavApplications.mapRadio;
      viewer = viewers[index];
      this.viewerIndex = index;
    } else {
      viewer = window.viewer;
    }
    this.getTagGroupListFn();
    this.init_events();
  },
  methods: {
    ...message,
    init_events() {
      let handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);
      handler.setInputAction((movement) => {
        //获取当前相机高度
        let height = Math.ceil(viewer.camera.positionCartographic.height);
        this.height = height;
      }, Cesium.ScreenSpaceEventType.WHEEL);
      handler.setInputAction(async (movement) => {
        let pickedLabel = viewer.scene.pick(movement.position);
        var pick = new Cesium.Cartesian2(
          movement.position.x,
          movement.position.y
        );
        if (Cesium.defined(pickedLabel)) {
          // 获取点击位置坐标
          let cartesian = viewer.scene.globe.pick(
            viewer.camera.getPickRay(pick),
            viewer.scene
          );
          if (
            markerArr.findIndex((item) => item.entities === pickedLabel.id) > -1
          ) {
            if (!document.getElementById(pickedLabel.id.id)) {
              let id = pickedLabel.id.id;
              let flag = true;
              if (this.bubbles?.id == id) {
                flag = false;
              }
              if (this.bubbles) {
                window.this_ = this;
                this.bubbles.windowClose(id);
                this.bubbles = null;
              }

              // if (bubblesList.length > 0) {
              //   bubblesList.some((v, i) => {
              //     console.log(v.vmInstance, id, "v.vmInstance.id === id");
              //     if (v.id === id) {
              //       v.windowClose();
              //       bubblesList.splice(i, 1);
              //       flag = false;
              //       return true; // 返回 true 中断循环
              //     }
              //   });
              // }
              if (flag) {
                this.bubble(id);
              }
            } else {
              if (this.bubbles) {
                this.bubbles.windowClose();
              }
            }
          }
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    },
    // 弹窗
    bubble(id) {
      // if (bubblesList.length > 0) {
      //   bubblesList.some((v, i) => {
      //     if (v.vmInstance.id === id) {
      //       v.windowClose();
      //       bubblesList.splice(i, 1);
      //       return true; // 返回 true 中断循环
      //     }
      //   });
      // }
      let aa = markerArr.find((item) => item.entities._id === id);
      this.bubbles = new Bubble(
        Object.assign(aa, {
          viewer,
        })
      );
      this.bubblesId = aa.id;
      // let b = this.bubbles;
      // bubblesList.push(b);
    },
    handleInputQj(name) {
      const inputValue = this.form[name];
      const sanitizedValue = inputValue.replace(/[\u4e00-\u9fa5]/gi, "");
      if (inputValue !== sanitizedValue) {
        this.form[name] = sanitizedValue;
      }
    },
    dblclickFn(item) {
      item.status = !item.status;
      this.checkboxCheckbox(item);
    },
    // handleClickAndGetEntityDataFn() {
    //   const _this = this;
    //   clickHandler = this.handleClickAndGetEntityData(
    //     viewer,
    //     Cesium.ScreenSpaceEventType.LEFT_CLICK,
    //     function (entityData) {
    //       // console.log('handleClickAndGetEntityData', entityData)
    //       if (entityData.properties) {
    //         const {
    //           _markName,
    //           _imageUrl,
    //           _streamUrl,
    //           _id,
    //           _panoramaUrl,
    //           _remark,
    //           _sources,
    //           _streamUrlType,
    //         } = entityData.properties;
    // let streamUrl = null;
    // if (_streamUrlType._value == "webrtc") {
    //   streamUrl = [
    //     {
    //       label: "WebRtc",
    //       brand: "SRS",
    //       url: _streamUrl._value,
    //     },
    //   ];
    // } else {
    //   streamUrl = [
    //     {
    //       label: _streamUrlType._value,
    //       brand: "liveNVR",
    //       url: _streamUrl._value,
    //     },
    //   ];
    // }
    //         const data = {
    //           id: _id._value,
    //           markName: _markName._value || "暂无",
    //           imageUrl: _imageUrl._value,
    //           panoramaUrl: _panoramaUrl._value,
    //           remark: _remark._value,
    //           sources: _sources._value,
    //           streamUrl: streamUrl,
    //         };
    //         if (
    //           !data.panoramaUrl &&
    //           !data.remark &&
    //           data.sources.length == 0 &&
    //           !data.streamUrl
    //         ) {
    //           return false;
    //         }
    //         _this.entitiesModelData = data;
    //         if (data.id) {
    //           _this.entitiesModelFlag = true;
    //         }
    //       }
    //     }
    //   );
    // },
    async saveReport() {
      await this.getImage();
    },
    async getImage() {
      const echarts =
        document.getElementById("chartType").childNodes[0].childNodes[0];
      const ids = this.reportData.map((i) => i.id);
      const canvas = document.createElement("canvas");
      canvas.width = echarts.videoWidth || echarts.width;
      canvas.height = echarts.videoHeight || echarts.width;
      const canvasContext = canvas.getContext("2d");
      canvasContext.drawImage(echarts, 0, 0);
      canvas.toBlob(
        async (blob) => {
          if (blob) {
            const data = new FormData();
            data.append("file", blob, "标注.png");
            data.append("ids", ids.join(","));
            data.append("sort", "asc");
            data.append("sortType", this.reportStatus);
            const res = await ThreeDimensionalApi.getMarkReport(data);
            if (res) {
              this.downloadBlob(res, "标注类型统计报告");
            } else {
              this.$message({
                type: "error",
                message: res.msg || "出错了",
                duration: 1000,
              });
            }
          }
        },
        "image/png",
        1
      );
    },
    downloadBlob(content, filename) {
      const blob = new Blob([content], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=utf-8",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      link.click();
      URL.revokeObjectURL(url);
    },
    initPieChart(data) {
      const obj = {};
      const dataArr = data.map((ele) => ele.markLabes).flat();
      dataArr.map((e) => {
        if (Object.keys(obj).includes(e.name)) {
          obj[e.name] += 1;
        } else {
          obj[e.name] = 1;
        }
      });
      const chartData = Object.keys(obj).map((s) => ({
        name: s,
        value: obj[s],
      }));
      const chartDom = document.getElementById("chartType");
      const myChart = echarts.init(chartDom);
      myChart.clear();
      let option;
      option = {
        title: {
          //   text: 'Referer of a Website',
          //   subtext: 'Fake Data',
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "数量",
            type: "pie",
            radius: "50%",
            data: chartData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };
      option && myChart.setOption(option);
    },
    editDimension(item) {
      this.closeFn();
      if (this.bubbles) {
        this.bubbles.windowClose();
        this.bubbles = null;
      }
      const newItem = JSON.parse(JSON.stringify(item));
      this.form = newItem;
      this.form.markLabes = newItem.markLabes.map((res) => {
        return res.id;
      });
      const fileList = [];
      const videoList = [];

      if (newItem.sources.length > 0) {
        newItem.sources.forEach((item) => {
          if (item.sourceType == 2) {
            videoList.push({
              name: item.sourceUrl,
              url: item.sourceUrl,
            });
          } else {
            fileList.push({
              name: item.sourceUrl,
              url: item.sourceUrl,
            });
          }
        });
      }
      this.dialogImg = newItem.imageUrl;
      if (newItem.markType == "POINT") {
        this.title = "编辑点标注";
      } else if (newItem.markType == "POLYGON") {
        this.title = "编辑线标注";
      } else {
        this.title = "编辑面标注";
      }
      // this.showArea(newItem, false, 'edit');
      viewer.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(item.lng, item.lat, 100),
      });
      setTimeout(() => {
        const uploadVideoDom = this.$refs.uploadVideo;
        uploadVideoDom.fileList = videoList;
        const dom = this.$refs.uploadFiledom;
        dom.fileList = fileList;
      }, 10);
    },
    async delItem(id) {
      if (this.bubbles) {
        if (this.bubblesId == id) {
          this.bubbles.windowClose();
          this.bubbles = null;
        }
      }
      // 如果删除的是当前正在编辑的，则关闭弹窗·
      if (this.form?.id == id) {
        this.closeFn();
      }
      const ids = id + "";
      this.removeMarker(id);
      const res = await ThreeDimensionalApi.removeBatch({ ids: ids });
      if (res.code == 200) {
        if (this.records.length > 1) {
          this.getmarkerLists(this.paginationData.currentPage);
        } else {
          if (this.paginationData.currentPage > 1) {
            this.getmarkerLists(this.paginationData.currentPage - 1);
          } else {
            this.getmarkerLists(1);
          }
        }

        this.saveAactiveItems();
      }
    },
    showreportDialog() {
      if (markerArr.length > 0) {
        // 保存当前数据状态
        this.reportDialog = true;
      } else {
        this.$message.warning("请选择标注");
      }
    },
    handleCurrentChange(val) {
      // 保存当前数据状态
      this.saveAactiveItems();
      // 请求数据
      this.getmarkerLists(val);
    },
    layerBoxSelectBoxCancellation() {
      this.layerBoxSelectBoxflag = false;
    },

    layerBoxSelectBoxFn() {
      const arr = [];
      this.tagTypeList[this.selectIndex].labelOuts.forEach((item) => {
        if (item.status) {
          arr.push(item);
        }
      });
      this.labels = arr;
      this.layerBoxSelectBoxflag = false;
    },
    // 监听多选框
    checkboxChange() {
      let isAllTrue = true;
      const array = this.tagTypeList[this.selectIndex].labelOuts;
      if (array.length > 0) {
        for (let i = 0; i < array.length; i++) {
          if (!array[i].status) {
            isAllTrue = false;
            break;
          }
        }
      }

      this.isAll = isAllTrue;
    },
    checkboxCheckbox(item) {
      // if (item.status) {
      // this.showArea(item, false, 'edit');
      viewer.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(item.lng, item.lat, 100),
      });
      // }
      this.saveAactiveItems();
    },

    async getTagGroupListFn() {
      const res = await ThreeDimensionalApi.getTagGroupList();
      if (res.code === 200) {
        const data = res.data.map((item) => {
          return {
            ...item,
            labelOuts:
              item.labelOuts && item.labelOuts.length > 0
                ? item.labelOuts.map((k) => {
                    return {
                      ...k,
                      status: false,
                    };
                  })
                : null,
          };
        });
        this.tagTypeList = data;
      }
    },
    SelectItemFn(num) {
      if (num == 1 && this.SelectItem == 1) {
        this.SelectItem = 0;
      } else if (num == 1) {
        this.SelectItem = num;
      }
      if (num == 3) {
        this.del();
      }
    },
    activeShow() {
      switch (this.active) {
        case 0:
          return "全部";
        case 1:
          return "点标注";
        case 2:
          return "线标注";
        case 3:
          return "面标注";
        default:
          break;
      }
    },
    typeFiter(markType) {
      if (markType == 1) {
        return "点标注";
      } else if (markType == 2) {
        return "线标注";
      } else {
        return "面标注";
      }
    },
    removeMarker(id) {
      const marker = markerArr.filter((e) => e.id == id);
      if (marker.length > 0) {
        viewers[viewerIndex].entities.remove(marker[0].entities);
        markerArr = markerArr.filter((e) => e.id != id);
        return;
      }
    },
    removeMarkerAll() {
      if (markerArr.length > 0) {
        markerArr.forEach((element) => {
          viewers[viewerIndex].entities.remove(element.entities);
        });
        markerArr = [];
      }
    },
    convertCoordinatesStringToXYZ(str) {
      function parseCoordinatesString(str) {
        const cleanedStr = str.replace(/[()]/g, "").replace(/,/g, "");
        const numStrArray = cleanedStr.split(" ");
        const coordinates = numStrArray.map((numStr) => parseFloat(numStr));
        const arrayObject = [];
        for (let i = 0; i < coordinates.length; i += 2) {
          // 修改循环步长为3
          const point = {
            x: coordinates[i],
            y: coordinates[i + 1],
            z: 0,
          };
          arrayObject.push(point);
        }
        return arrayObject;
      }

      function convertDegreesToXYZ(latitude, longitude, altitude) {
        const position = Cesium.Cartesian3.fromDegrees(
          longitude,
          latitude,
          altitude
        );
        const x = position.x;
        const y = position.y;
        const z = position.z;
        return { x, y, z };
      }

      const coordinatesArray = parseCoordinatesString(str);
      const xyzArray = coordinatesArray.map((coord) => {
        const { x, y, z } = convertDegreesToXYZ(coord.y, coord.x, 0); // 假设海拔为0
        return { x, y, z };
      });

      return xyzArray;
    },
    showArea(item, flyTo, type) {
      if (type != "edit") {
        const marker = markerArr.filter((e) => e.id == item.id);
        if (marker.length > 0) {
          viewers[viewerIndex].entities.remove(marker[0].entities);
          markerArr = markerArr.filter((e) => e.id != item.id);
          return;
        }
      }

      const iconArr = this.iconArr.filter((k) => {
        return k.id == item.styleType;
      });
      let img = null;
      if (iconArr.length > 0) {
        img = iconArr[0].icoUrl;
      }
      let state;
      const positions = this.convertCoordinatesStringToXYZ(item.markLine);
      if (item.markType == "POINT") {
        state = "point";
      } else if (item.markType == "LINESTRING") {
        state = "line";
      } else if (item.markType == "POLYGON") {
        state = "polygon";
      }
      let data = {
        id: item.id,
        positions,
        image: img,
        title: item.markName,
        state,
        color: item.styleLineOutColor,
        methodState: "add",
        width: item.styleLineSize,
        polyCenter: Cesium.BoundingSphere.fromPoints(positions).center,
        outlineColor: item.styleLineOutColor || "#238ff3",
        markerId: item.id,
        markInfo: item,
      };
      if (type != "edit") {
        data = this.createLabelEntities(viewer, data, state);
        markerArr.push(data);
      } else {
        data = this.createLabelEntities(viewer, data, state);
        dataEntities = data.entities;
      }
      if (!flyTo) {
        viewer.flyTo(data.entities); // 飞入实例
      }
    },
    isAllTrue(array) {
      return array.every(function (element) {
        return element.status === true;
      });
    },
    // 全选
    all() {
      // 清空标记点
      // this.removeMarkerAll();
      const flag = this.isAllTrue(this.records);
      if (!flag) {
        this.records.map((i) => {
          return (i.status = true);
        });
      } else {
        this.records.map((i) => {
          return (i.status = false);
        });
      }
      // 保存保存选中标注
      this.saveAactiveItems();
      // setTimeout(() => {
      //   this.showEntites();
      // }, 10);
    },
    // 保存选中标注
    saveAactiveItems() {
      this.activeItems[this.paginationData.currentPage] = this.records.filter(
        (i) => {
          return i.status == true;
        }
      );
      const reportData = [];
      for (const key in this.activeItems) {
        if (Object.hasOwnProperty.call(this.activeItems, key)) {
          const array = this.activeItems[key];
          for (let i = 0; i < array.length; i++) {
            const item = array[i];
            reportData.push(item);
          }
        }
      }
      setTimeout(() => {
        this.reportData = reportData;
      }, 10);
    },
    showEntites() {
      for (const key in this.activeItems) {
        if (Object.hasOwnProperty.call(this.activeItems, key)) {
          const array = this.activeItems[key];
          for (let i = 0; i < array.length; i++) {
            const item = array[i];
            this.showArea(item, true);
          }
        }
      }
    },
    // 反选
    InvertSelection() {
      // 清空标记点
      // this.removeMarkerAll();
      this.records.map((i) => {
        return (i.status = !i.status);
      });
      // 保存选中
      this.saveAactiveItems();
      // setTimeout(() => {
      //   this.showEntites();
      // }, 10);
    },
    // 删除标注
    del() {
      const id = [];
      this.records.forEach((element) => {
        if (element.status) {
          if (element.id) {
            this.removeMarker(element.id);
          }
          id.push(element.id);
        }
      });
      if (id.length == 0) {
        return this.$message.warning("请选择需要删除的标注！");
      }
      this.removeBatchFn(id.join(","));
    },
    async removeBatchFn(id) {
      const res = await ThreeDimensionalApi.removeBatch({ ids: id });
      if (res.code == 200) {
        this.$message.success("删除成功！");
        if (this.records.length > id.length) {
          this.getmarkerLists(this.paginationData.currentPage);
        } else {
          if (this.paginationData.currentPage > 1) {
            this.getmarkerLists(this.paginationData.currentPage - 1);
          } else {
            this.getmarkerLists(1);
          }
        }
      }
    },
    handleIconClick() {
      this.getmarkerLists(1);
    },
    // 获取标注列表
    async getmarkerLists(currentPage) {
      const ids = this.labels && this.labels.map((res) => res.labelId);
      const labels = (ids && ids.join(",")) || null;
      let markLevel = null;

      try {
        markLevel = this.markLevel
          ? String(this.markLevel)
          : this.markLevel === 0
          ? "1,2,3,4,5"
          : null;
      } catch (error) {
        console.log(error);
      }

      const res = await ThreeDimensionalApi.getmarkerList({
        currentPage: currentPage || 1,
        pageSize: 5,
        markLevel: markLevel,
        markName: this.search || null,
        labels: labels,
      });

      if (res.code == 200) {
        this.paginationData = {
          total: res.data.totalCount,
          totalPage: res.data.totalPage,
          currentPage: res.data.currentPage,
          size: res.data.pageSize,
        };

        this.$forceUpdate();

        const arr = this.activeItems[res.data.currentPage] || null;

        const data = res.data.items.map((item) => ({
          ...item,
          status: this.findItem(arr, item.id),
          // markLevel: item.markLevel ? item.markLevel + "级" : null,
          tagList:
            item.tagList?.length > 0
              ? item.tagList.map((i) => i.name).join("、")
              : null,
          // markType: item.markType == 'POINT' ? '点标注' : item.markType == 'LINESTRING' ? '线标注' : '面标注'
        }));

        this.records = data;
        this.removeMarkerAll();
        setTimeout(() => {
          // this.showEntites();
          for (let i = 0; i < data.length; i++) {
            const item = data[i];
            this.showArea(item, true);
          }
        }, 10);
      } else {
        this.$message.error(res.msg);
      }
    },
    findItem(arr, id) {
      if (arr && arr.length > 0) {
        var item = arr.find(function (element) {
          return element.id === id;
        });

        return item !== undefined;
      } else {
        return false;
      }
    },
    async tagListFn() {
      const res = await ThreeDimensionalApi.getAllLabels();
      if (res.code == 200) {
        this.tagList = res.data;
      }
    },
    convertXYZArrayToCoordinatesString(array, type) {
      function convertXYZToDegrees(x, y, z) {
        const position = new Cesium.Cartesian3(x, y, z);
        const cartographic = Cesium.Cartographic.fromCartesian(position);
        const longitude = Cesium.Math.toDegrees(cartographic.longitude);
        const latitude = Cesium.Math.toDegrees(cartographic.latitude);
        // const altitude = cartographic.height;
        return { latitude, longitude };
      }

      const coordinatesArray = array.map((item) => {
        const { latitude, longitude } = convertXYZToDegrees(
          item.x,
          item.y,
          item.z
        );
        return `${longitude} ${latitude}`;
      });
      if (type == "POLYGON") {
        const coordinatesString = `((${coordinatesArray.join(", ")}))`;
        return coordinatesString;
      } else {
        const coordinatesString = `(${coordinatesArray.join(", ")})`;
        return coordinatesString;
      }
    },
    // 保存标注
    onSubmit() {
      if (!this.handle_screenshotFlag) {
        return this.$message.warning("请完成截图操作！");
      }
      const markLine = this.form.markLine.includes("(")
        ? this.form.markLine
        : this.convertMarkLine(this.form.markLine);
      this.$refs["formRef"].validate(async (valid) => {
        const sources = [];
        if (valid) {
          // this.form.tagList = JSON.stringify(this.form.tagList);
          this.loading = true;
          const form = { ...this.form };
          const dom = this.$refs.uploadFiledom;
          const uploadVideoDom = this.$refs.uploadVideo;

          if (dom.fileList && dom.fileList.length > 0) {
            for (let i = 0; i < dom.fileList.length; i++) {
              const el = dom.fileList[i];
              const params = new FormData();
              if (el.raw) {
                params.append("file", el.raw);
                const res = await AirLine.uploadFile(params);

                if (res.code == 200) {
                  sources.push({
                    sourceType: 1,
                    sourceUrl: res.msg,
                  });
                }
              } else {
                sources.push({
                  sourceType: 1,
                  sourceUrl: el.url,
                });
              }
            }
          }
          if (uploadVideoDom.fileList && uploadVideoDom.fileList.length > 0) {
            for (let i = 0; i < uploadVideoDom.fileList.length; i++) {
              const el = uploadVideoDom.fileList[i];
              const params = new FormData();
              if (el.raw) {
                params.append("file", el.raw);
                try {
                  const res = await AirLine.uploadFile(params);
                  if (res.code == 200) {
                    sources.push({
                      sourceType: 2,
                      sourceUrl: res.msg,
                    });
                  }
                } catch (error) {
                  this.$message.warning("视频上传失败");
                  this.loading = false;
                  return;
                }
              } else {
                sources.push({
                  sourceType: 2,
                  sourceUrl: el.url,
                });
              }
            }
          }
          form.sources = sources;
          form.markLine = markLine;
          delete form.tagList;
          let res = null;
          if (!form.id) {
            res = await ThreeDimensionalApi.addMarker(form);
          } else {
            delete form.status;
            res = await ThreeDimensionalApi.editMarker(form);
          }

          if (res.code == 200) {
            this.$message.success("操作成功");
            this.form.markLevel = 0;
            this.getmarkerLists(1, "add");
            this.closeFn();
            this.loading = false;
            // this.$bus.$emit('getMarkMonitorList'); // 通知监控参数组件更新数据
          } else {
            this.$message.error(res.message);
            this.loading = false;
          }
        }
      });
    },

    convertMarkLine(markLine) {
      const markLineCopy = JSON.parse(markLine);
      if (this.form.markType == "POLYGON") {
        markLineCopy.push(markLineCopy[0]);
      }
      return this.convertXYZArrayToCoordinatesString(
        markLineCopy,

        this.form.markType
      );
    },
    // 更新位置
    updateFn() {
      const markType = this.form.markType;
      this.add(this.entitieType[markType]);
    },
    // 关闭新增标出弹框
    closeFn() {
      this.form = {
        markSize: null, // 面积
        code: null,
        place: null,
        lng: null,
        lat: null,
        markLevel: 1,
        markName: "默认标注",
        remark: "",
        markType: null, // 标注类型 1点标注 2 线标注 3面标注
        imageUrl: null,
        styleLineSize: 5, // 线宽
        styleType: 1, // 高程
        styleLineAreaColor: "#ea4141", // 面积颜色
        styleLineOutColor: "#ea4141", // 颜色
        markLine: null,
      };
      this.title = null;
      viewer.entities.remove(dataEntities);
      this.img = require("@/assets/three-dimensional/acquiescent.png");
      this.dialogImg = require("@/assets/three-dimensional/acquiescent.png");
    },
    // 飞入标注
    handle_flyTo() {
      if (dataEntities) {
        viewer.flyTo(dataEntities);
      }
    },
    // 切换标注中的table
    changeTble(num) {
      this.itemIndex = num;
    },
    // 关闭弹窗
    closeIDsPopup() {
      this.$emit("closeDemand");
    },
    // 获取封面
    handle_screenshot() {
      this.handle_screenshotFlag = false;
      screenshot(this.viewer, async (img) => {
        if (img) {
          this.dialogImg = img.src;
          img = await this.getfileUpload(img.src);
          document.body.style.cursor = "default";
        }
        this.handle_screenshotFlag = true;
      });
    },
    async getfileUpload(src) {
      const params = new FormData();
      const blobFile = dataURItoBlob(src);
      params.append("file", blobFile);
      const res = await AirLine.uploadFile(params);
      this.form.imageUrl = res.msg;
    },
    // cesium生成标注
    add(state) {
      viewers[viewerIndex].entities.remove(dataEntities);
      this.itemIndex = 0;
      drawGraphic(viewer, state, async (earthPosition, floatingPoint = []) => {
        const cartesian3 = earthPosition[0] || null;
        if (cartesian3) {
          const ellipsoid = viewer.scene.globe.ellipsoid;
          const cartographic = ellipsoid.cartesianToCartographic(cartesian3);
          const lat = Cesium.Math.toDegrees(cartographic.latitude).toFixed(6);
          const lng = Cesium.Math.toDegrees(cartographic.longitude).toFixed(6);
          this.form.lat = lat;
          this.form.lng = lng;
        }
        let styleType = null;
        if (state == "point") {
          styleType = this.iconArr[0].icoUrl;
          this.form.styleType = this.iconArr[0].id;
        }
        let data = {
          positions: earthPosition,
          image: styleType,
          title: "默认标注",
          state,
          color: this.form.styleLineOutColor,
          methodState: "add",
          width: 5,
          polyCenter: Cesium.BoundingSphere.fromPoints(earthPosition).center,
          outlineColor: "#238ff3",
        };
        floatingPoint.map((item) => {
          viewer.entities.remove(item);
        });
        if (state == "line" && earthPosition.length < 2) {
          return this.$message.warning("线标注为两个点或两个点以上");
        } else if (state == "polygon" && earthPosition.length < 3) {
          return this.$message.warning("面标注为三个点或三个点以上");
        }
        data = this.createLabelEntities(viewer, data, state);
        dataEntities = data.entities;
        // 保存当前 dataEntities 操作索引
        viewerIndex = this.viewerIndex;
        this.form.markLine = JSON.stringify(data.positions);
        this.form.code = Date.now().toString();
        if (state == "point") {
          this.form.markType = "POINT";
          this.title = this.form.id ? "编辑点标注" : "新增点标注";
        } else if (state == "line") {
          this.form.markType = "LINESTRING";
          this.title = this.form.id ? "编辑线标注" : "新增线标注";
        } else if (state == "polygon") {
          this.form.markType = "POLYGON";
          this.title = this.form.id ? "编辑面标注" : "新增面标注";

          const area = this.getArea(dataEntities);
          this.form.markSize = area;
        }
        this.$store.commit("uavApplications/setState", {
          key: "isPreventClick",
          value: false,
        });
        const res = await Map.regeo({
          key: "14f103d36f3bfa593bccb770c960e7d9",
          location: `${this.form.lng}, ${this.form.lat}`,
        });
        if (res.status === 200) {
          this.form.place = res.data.regeocode.formatted_address;
        }
      });
    },

    //  创建标注实例
    createLabelEntities(viewer, data, state) {
      let entities;
      if (state == "point") {
        entities = createPoint(viewer, {
          position: data.positions[0],
          image: data.image,
          title: data.title,
          markInfo: data.markInfo,
        });
      } else if (state == "line") {
        entities = createLine(viewer, {
          polyCenter: data.polyCenter,
          positions: data.positions,
          width: data.width,
          color: data.color,
          title: data.title,
          markInfo: data.markInfo,
        });
      } else {
        entities = createPolygon(viewer, {
          polyCenter: data.polyCenter,
          positions: data.positions,
          color: data.color,
          title: data.title,
          outlineColor: data.outlineColor,
          markInfo: data.markInfo,
        });
      }
      data.entities = entities;
      // viewer.flyTo(entities)
      return data;
    },
  },
};
</script>
<style lang="scss" scoped>
.text:hover {
  color: #1f4dcb;
}

.f569 {
  color: #00f569;
}

.layerBox {
  height: 77vh;
  padding: 10px;

  // overflow-y: auto;
  // overflow-x: hidden;
  .layerBoxSelect {
    background: #161d2b;
    min-height: 48px;
    border: 1px solid transparent;

    .layerBoxSelectItem {
      display: flex;
      justify-content: space-around;
      color: #b3bbc5;
    }

    .layerBoxSelectItem_active {
      color: #4a9be8;
    }

    .searchSelect {
      width: 194px;
      margin-left: 9px;
      display: inline-block;
    }

    .layerBoxSelectBox {
      display: block;
      width: 220px;
      height: 328px;
      position: absolute;
      top: 20px;
      left: 475px;
      background: #2a3243;
      box-shadow: inset 0px 0px 0px 0px #3e485c;
      border-radius: 2px;
      z-index: 10;
      padding: 15px 8px;
      display: flex;
      box-sizing: border-box;

      .layerBoxSelectBox_left {
        width: 94px;
        height: 256px;
        box-sizing: border-box;
        padding: 6px;
        box-shadow: inset 0px 0px 0px 0px #3e485c;
        border-radius: 2px;
        border: 1px solid #000000;
        overflow-y: auto;
        cursor: pointer;
        text-align: center;

        .label {
          color: #4a9ce8;
        }

        .active {
          background-color: #50abff;
          color: #fff;
        }
      }

      .layerBoxSelectBox_right {
        width: 124px;
        height: 256px;
        background-color: #14151a;
        .label {
          height: 32px;
          line-height: 32px;
          color: #888f98;
          padding-left: 12px;
          background-color: #14151a;

          .name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 67px;
            margin: -12px auto;
            margin-left: 10px;
          }
        }
      }
    }
  }

  .titleBox {
    height: 34px;
    line-height: 34px;
    background: rgba(138, 152, 177, 0.18);
  }

  &::v-deep {
    .el-input--mini {
      box-shadow: inset 0px 1px 3px 0px #00142f;
      border: 1px solid #73a6d1;
      backdrop-filter: blur(2px);
    }

    .el-input--mini .el-input__inner {
      // border: 1px solid #73a6d1;
      box-shadow: inset 0px 1px 3px 0px #00142f;
      // background: rgba(21, 95, 192, 0.3);
      color: #fff;
    }
  }

  .itemBox {
    height: 63vh;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;

    .item {
      // height: 152px;
      margin-bottom: 10px;
      background: rgba(0, 0, 0, 0.2);
      // border-radius: 2px;
      // border: 1px solid #73a6d1;
      backdrop-filter: blur(4px);
      box-sizing: border-box;
      border: 1px solid transparent;
      border-bottom: 1px solid #131417;

      .title {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #ebf5ff;
      }

      .item_bottom {
        // display: flex;
        display: none;
        justify-content: center;
      }

      .tags {
        display: inline-block;
        min-width: 42px;
        text-align: center;
        height: 18px;
        border-radius: 2px;
        border: 1px solid #71757e;
        font-size: 9px;
        color: #71757e;
        margin-right: 8px;
        line-height: 18px;
      }

      .time {
        width: 231px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        color: #91959c;
      }
    }

    .item:hover {
      .item_bottom {
        display: flex;
      }
    }

    .itemActive {
      border: 1px solid #4796df;
      border-radius: 3px;
    }
  }
}

.list {
  background: rgba(19, 21, 24, 0.7);
  display: flex;

  .item {
    // width: calc(100% / 3);
    flex: 1;
    text-align: center;
    color: #ffffff;
    height: 34px;
    line-height: 34px;
    cursor: pointer;
  }

  .active {
    color: #0984ff;
    background: #0a0b0d;
    border-top: 1px solid #1d476c;
  }
}

.btn2 {
  width: 73px;
  height: 32px;
  cursor: pointer;
  line-height: 32px;
  border-radius: 3px;
  text-align: center;
  background: #50abff;
}

.btn {
  width: 86px;
  height: 30px;
  cursor: pointer;
  line-height: 30px;
  text-align: center;
  background: rgba(38, 71, 238, 0.71);
  box-shadow: inset 0px 1px 3px 0px #ffffff,
    inset 0px -2px 3px 0px rgba(0, 18, 55, 0.5);
  border-radius: 2px;
  border: 1px solid;
  border-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0)
    )
    1 1;
}

#closeIDsPopupdiv {
  position: relative;

  ::v-deep {
    .el-input__inner {
      background: #16181b;
      // border: 1px solid #73a6d1;
      min-width: 146px;
      color: #fff;
      border-radius: 0px;
    }

    .el-textarea__inner {
      // background: rgba(21, 95, 192, 0.1);
      border: 1px solid #73a6d1;
      color: #fff;
    }

    .el-color-picker__trigger {
      width: 50px;
    }

    .el-checkbox__inner {
      background-color: #16181b;
      border: 1px solid #b3bbc5;
    }

    .el-select--mini {
      margin-left: 0px !important;
    }

    .el-form-item__label {
      color: #fff;
    }
  }
}

.markTypeDialog {
  .target {
    width: 25px;
    height: 25px;
    border-radius: 5px;
    background: #50abff;
    margin-top: 10px;
  }
}

.reportBox {
  position: absolute;
  left: 400px;
  top: 100px;
  width: 320px;
  height: 319px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5),
    0px 2px 4px 0px rgba(0, 0, 0, 0.5),
    inset 0px 16px 48px 0px rgba(93, 173, 255, 0.15),
    inset 0px 4px 48px 0px rgba(53, 76, 147, 0.5), inset 0px 1px 4px 0px #2e63dc;
  border-radius: 4px;
  border: 1px solid;
  border-image: linear-gradient(
      180deg,
      rgba(52, 223, 255, 1),
      rgba(24, 113, 214, 0)
    )
    1 1;
  backdrop-filter: blur(4px);
  z-index: 2;
  background-color: #0a0b0d;

  .header {
    display: flex;
    justify-content: space-between;
    color: #dae3ef;
    padding: 0 10px;
    box-sizing: border-box;
    width: 100%;
    background-color: #293449;
    height: 32px;
    line-height: 32px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5),
      0px 1px 0px 0px rgba(255, 255, 255, 0.05), inset 0px -1px 0px 0px #000000;
    border-radius: 4px 4px 0px 0px;
  }

  .content {
    margin-top: 10px;

    .th {
      display: flex;
      color: #b3bbc5;
      height: 32px;

      span {
        background-color: #14151a;
        width: 49.5%;
        line-height: 32px;
        font-size: 14px;
        text-align: center;
      }
    }
  }

  .report_list {
    height: 52%;
    overflow-y: auto;
    background: #0a0b0d;

    .item {
      height: 32px;
      line-height: 32px;
      color: #b3bbc5ff;
      background-color: #14151a;
      text-align: center;
    }

    .item:nth-child(odd) {
      background-color: #0a0b0d;
    }
  }

  .screen {
    color: #dae3ef;
    display: flex;
    font-size: 14px;
    line-height: 24px;
    justify-content: flex-start;
  }
}

.report_list_title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.InspectionReport {
  position: absolute;
  left: 800px;
  top: 0px;
  width: 600px;
  /* height: 100%; */
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5),
    0px 2px 4px 0px rgba(0, 0, 0, 0.5),
    inset 0px 16px 48px 0px rgba(93, 173, 255, 0.15),
    inset 0px 4px 48px 0px rgba(53, 76, 147, 0.5), inset 0px 1px 4px 0px #2e63dc;
  border-radius: 4px;
  border: 1px solid;
  border-image: linear-gradient(
      180deg,
      rgba(52, 223, 255, 1),
      rgba(24, 113, 214, 0)
    )
    1 1;
  backdrop-filter: blur(4px);
  z-index: 2;
  background-color: #0a0b0d;

  .header {
    display: flex;
    justify-content: space-between;
    color: #dae3ef;
    padding: 0 10px;
    box-sizing: border-box;
    width: 100%;
    background-color: #293449;
    height: 32px;
    line-height: 32px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5),
      0px 1px 0px 0px rgba(255, 255, 255, 0.05), inset 0px -1px 0px 0px #000000;
    border-radius: 4px 4px 0px 0px;
  }
  .InspectionReport-label {
    background-color: #fff;
  }

  .content {
    margin-top: 10px;

    .th {
      display: flex;
      color: #b3bbc5;
      height: 32px;

      span {
        background-color: #14151a;
        width: 49.5%;
        line-height: 32px;
        font-size: 14px;
        text-align: center;
      }
    }
  }

  .report_list {
    height: 54%;
    overflow-y: auto;
    background: #0a0b0d;

    .item {
      height: 32px;
      line-height: 32px;
      color: #b3bbc5ff;
      background-color: #14151a;
      text-align: center;
    }

    .item:nth-child(odd) {
      background-color: #0a0b0d;
    }
  }

  .screen {
    color: #dae3ef;
    display: flex;
    font-size: 14px;
    line-height: 24px;
    justify-content: flex-start;
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hidden {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#chartType {
  width: 100%;
  height: 300px;
  background-color: #fff;
}
::v-deep {
  .el-form-item__label {
    color: #fff;
  }
}
.dialog_box {
  position: fixed;
  top: 100px;
  right: 10%;
  width: 320px;
  background: #0a0b0d;
  border: 1px solid #73a6d1;
  box-shadow: 0px 0px 5px #73a6d1;
  border-top: 0;
  backdrop-filter: blur(2px);
  .titleBox {
    height: 34px;
    padding: 0;
    box-sizing: border-box;
    background: rgba(21, 95, 192, 0.3);
    box-shadow: inset 0px 0px 10px 0px #00142f;
    border: 1px solid #73a6d1;
    backdrop-filter: blur(2px);
    .close {
      color: #fff;
      cursor: pointer;
      margin-right: 10px;
      line-height: 33px;
    }
    .title {
      color: #fff;
      margin-left: 10px;
      line-height: 29px;
    }
  }
}
</style>
