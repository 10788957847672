/*
 * @Author: kai 17660422924@163.com
 * @Date: 2024-03-15 16:35:23
 * @LastEditors: kai 17660422924@163.com
 * @LastEditTime: 2024-03-18 09:56:49
 * @FilePath: \mmc-vue-tianmu\src\utils\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Base64 } from 'js-base64';

// 清空cookie
export function clearCookie() {
  var keys = document.cookie.match(/[^ =;]+(?=\=)/g);
  if (keys) {
    for (var i = keys.length; i--;) {
      document.cookie = keys[i] + '=0;path=/;expires=' + new Date(0).toUTCString(); // 清除当前域名下的,例如：m.kevis.com
      document.cookie =
        keys[i] + '=0;path=/;domain=' + document.domain + ';expires=' + new Date(0).toUTCString(); // 清除当前域名下的，例如 .m.kevis.com
      document.cookie = keys[i] + '=0;path=/;domain=kevis.com;expires=' + new Date(0).toUTCString(); // 清除一级域名下的或指定的，例如 .kevis.com
    }
  }
}
// 过滤对象属性
export const filterObjAttr = (obj, key) => {
  return Object.keys(obj).reduce((pre, cur) => {
    if (!key.includes(cur)) {
      pre[cur] = obj[cur];
    }
    return pre;
  }, {});
};

// 生成随机数
export const getRandomInt = (maxNum, minNum) => {
  return parseInt(String(Math.random() * (maxNum - minNum + 1) + minNum), 10);
};
export const urlSafeBase64Encode = (input) => {
  const base64Chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';
  let output = '';

  const utf8Bytes = unescape(encodeURIComponent(input));

  for (let i = 0; i < utf8Bytes.length; i += 3) {
    const a = utf8Bytes.charCodeAt(i);
    const b = utf8Bytes.charCodeAt(i + 1);
    const c = utf8Bytes.charCodeAt(i + 2);

    const index1 = a >> 2;
    const index2 = ((a & 3) << 4) | (b >> 4);
    const index3 = ((b & 15) << 2) | (c >> 6);
    const index4 = c & 63;

    output += base64Chars.charAt(index1);
    output += base64Chars.charAt(index2);
    output += base64Chars.charAt(index3);
    output += base64Chars.charAt(index4);
  }

  // 处理最后的填充字符 =
  const padding = output.length % 4;
  if (padding > 0) {
    output += base64Chars.charAt(base64Chars.length - 1).repeat(4 - padding);
  }

  return output;
}
// 转换拉流地址为可统计播放时间地址
export function coverStreamUrl(url, hardId) {
  url = url.trim();
  if (url.includes('live.mmcuav.cn')) {
    // 去除特殊字符串
    const str2 = urlSafeBase64Encode(JSON.stringify({
      token: localStorage.getItem('tmj_token'),
      actionId: new Date().getTime().toString(),
      deviceHardId: hardId,
      environment: process.env.VUE_APP_ENV === 'dev' ? 'sit' : 'prod'
    }))
    // return url;
    return `${url}?${str2}`;
  } else {
    return url;
  }
}
